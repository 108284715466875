<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.mobile.header') }}</p>
            <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">{{ $t('deposit.mobile.header') }}</p>
            <el-form label-position="top" :model="mobileForm" ref="mobileForm" status-icon :rules="mobileRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, mobileForm.amount)" prop="amount">
                      <numeric-input
                        v-model="mobileForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li class="data">
                    <p>
                      {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'CNY' }) }}
                      <span> {{ rate }}</span>
                    </p>
                    <p>
                      CNY: <span>{{ getRate(rmbChange) }}</span>
                    </p>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                      <el-input v-model="mobileForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm('mobileForm')" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-html="thirdPartyApiForm"></div>
      </div>
      <div class="form-right">
        <div class="logo rectangle mobile"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiMobile_payment, apiGet_mobile_rate } from '@/resource';
import { autoForm } from '@/util/getAutoForm';

export default {
  name: 'mobilePay',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && this.rmbChange > this.maxLimit) {
        callback(new Error(this.$t('deposit.mobile.formValidation.cnyAmt', { limit: this.maxLimit })));
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.getMaxUSCByRate(this.maxLimit, this.rate)) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      mobileForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      mobileRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'USC'],
      thirdPartyApiForm: '',
      rate: 0.0,
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst9'),
        this.$t('deposit.reminder.inst10')
      ],
      showNote: false
    };
  },
  computed: {
    rmbChange() {
      return rounding(Math.ceil, this.rate * this.mobileForm.amount, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.mobileForm.accountNumber = accountNumber;
      this.fetchMobileRate(accountNumber);
    },
    fetchMobileRate(value) {
      apiGet_mobile_rate({
        mt4_account: value
      })
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
          else this.errorMessage('Get error when fetching rate. Please fresh the page and try again later');
        })
        .catch(err => {
          this.errorMessage('Get error when fetching rate. Please fresh the page and try again later');
        });
    },
    submitDeposit() {
      return apiMobile_payment(
        {
          mt4Account: this.mobileForm.accountNumber,
          operateAmount: this.mobileForm.amount,
          applicationNotes: this.mobileForm.notes,
          paymentMethod: 'mobile',
          depositAmount: this.getRate(this.rmbChange),
          rate: this.rate
        },
        this.token
      );
    },
    success(msg) {
      this.thirdPartyApiForm = autoForm(msg);
      this.$nextTick(function() {
        if (document.getElementById('paysubmit')) {
          document.getElementById('paysubmit').submit();
        }
      });
    },
    submitForm() {
      this.$refs['mobileForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
