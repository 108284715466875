import { render, staticRenderFns } from "./MobilePay.vue?vue&type=template&id=163b2239&scoped=true&"
import script from "./MobilePay.vue?vue&type=script&lang=js&"
export * from "./MobilePay.vue?vue&type=script&lang=js&"
import style0 from "./MobilePay.vue?vue&type=style&index=0&id=163b2239&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163b2239",
  null
  
)

export default component.exports